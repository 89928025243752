import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 id="page-title">
          <a href="https://www.coljohnboyd.com">Colonel John Boyd</a>
        </h1>
      </header>
      <div className="App-body">
        <h2 id="objective">Objective</h2>
        <p>
          This site catalogs resources about Colonel John Boyd and his work. Its
          goal is to serve as a public, long-term digital archive for these
          resources.
        </p>
        <p>
          If you have corrections, new materials to add, questions, or
          suggestions for improving the site, please email coljohnboyd [at]
          outlook.com.
        </p>
        <p>As of November 2020, this site is under development.</p>
        <h2 id="contents">Contents</h2>
        <ul>
          <li>
            <a href="#books">Acknowledgements</a>
          </li>
          <li>
            <a href="#books">Books</a>
          </li>
          <li>
            <a href="#briefings-print">Briefings, print</a>
          </li>
          <li>
            <a href="#briefings-video">Briefings, video</a>
          </li>
          <li>
            <a href="#diagrams">Diagrams</a>
          </li>
          <li>
            <a href="#lectures-talks">Lectures & Talks</a>
          </li>
          <li>
            <a href="#personal-papers">Personal Papers</a>
          </li>
          <li>
            <a href="#photos">Photos</a>
          </li>
          <li>
            <a href="#videos">Videos</a>
          </li>
          <li>
            <a href="#webpages">Webpages</a>
          </li>
        </ul>
        <h2 id="books">Acknowledgements</h2>
        <p>
          <a href="#chet-richards">Chet Richards</a>, Ginger Richards,{" "}
          <a href="#chuck-spinney">Chuck Spinney</a>
        </p>
        <h2 id="books">Books</h2>
        <ul>
          <li>
            <span className="book-title">
              Airpower Reborn: The Strategic Concepts of John Warden and John
              Boyd
            </span>{" "}
            edited by John Andreas Olsen (2015){" "}
            <a href="https://www.amazon.com/Airpower-Reborn-Strategic-Concepts-Military/dp/1612518044">
              Amazon
            </a>
            ,{" "}
          </li>
          <li>
            <span className="book-title">
              Boyd: The Fighter Pilot Who Changed the Art of War{" "}
            </span>{" "}
            by Robert Coram (2002){" "}
            <a href="https://www.amazon.com/Boyd-Fighter-Pilot-Who-Changed/dp/0316796883">
              Amazon
            </a>
            ,{" "}
            <a href="https://usmc.primo.exlibrisgroup.com/permalink/01USMCU_INST/bst26c/alma991000826819705241">
              USMCRL
            </a>
          </li>
          <li>
            <span className="book-title">
              Certain to Win: The Strategy of John Boyd, Applied to Business{" "}
            </span>{" "}
            by Chet Richards (2004){" "}
            <a href="https://www.amazon.com/Certain-Win-Strategy-Applied-Business-ebook/dp/B0793SDYSM">
              Amazon
            </a>
          </li>
          <li>
            <span className="book-title">
              Control Warfare: Inside the OODA Loop - War College Series
            </span>{" "}
            by Michael T. Plehn (2015){" "}
            <a href="https://www.amazon.com/Control-Warfare-Inside-OODA-College/dp/1298474248">
              Amazon
            </a>
          </li>
          <li>
            <span className="book-title">
              Manipulating the OODA Loop: The Overlooked Role of Information
              Resource Management in Information Warfare{" "}
            </span>{" "}
            by Gregory M. Schechtman (2012){" "}
            <a href="https://www.amazon.com/Manipulating-OODA-Loop-Overlooked-Information/dp/1288317581">
              Amazon
            </a>
          </li>
          <li>
            <span className="book-title">
              The Mind of War: John Boyd and American Security{" "}
            </span>{" "}
            by Grant T. Hammond (2001){" "}
            <a href="https://www.amazon.com/Mind-War-John-American-Security/dp/158834178X">
              Amazon
            </a>
            ,{" "}
            <a href="https://usmc.primo.exlibrisgroup.com/permalink/01USMCU_INST/bst26c/alma991000781389705241">
              USMCRL
            </a>
            ,{" "}
            <a href="https://www.google.com/books/edition/_/5Z9fBgAAQBAJ">
              Google Books
            </a>
          </li>
          <li>
            <span className="book-title">
              A new conception of war: John Boyd, the U.S. Marines, and maneuver
              warfare{" "}
            </span>{" "}
            by Ian T. Brown (2018){" "}
            <a href="https://www.usmcu.edu/Portals/218/ANewConceptionOfWar.pdf?ver=2018-11-08-094859-167">
              USMC University
            </a>
            ,{" "}
            <a href="https://www.amazon.com/Conception-War-Marines-Maneuver-Warfare/dp/B081K8F763">
              Amazon
            </a>
            ,{" "}
            <a href="https://usmc.primo.exlibrisgroup.com/permalink/01USMCU_INST/4bne2q/alma991003578799505241">
              USMCRL
            </a>
          </li>
          <li>
            <span className="book-title">
              Science, Strategy and War: The strategic theory of John Boyd
            </span>{" "}
            by Frans P.B. Osinga (2007){" "}
            <a href="https://www.amazon.com/Science-Strategy-War-Strategic-History/dp/0415459524">
              Amazon
            </a>
            ,{" "}
            <a href="https://usmc.primo.exlibrisgroup.com/permalink/01USMCU_INST/bst26c/alma991000939949705241">
              USMCRL
            </a>
            ,{" "}
            <a href="https://www.google.com/books/edition/_/3DtoVuj_Sn4C">
              Google Books
            </a>
          </li>
          <li>
            <span className="book-title">
              A Vision So Noble: John Boyd, the OODA Loop, and America's War on
              Terror
            </span>{" "}
            by Daniel Ford (2013){" "}
            <a href="https://www.amazon.com/Vision-So-Noble-Americas-Terror-ebook/dp/B003LO1GFU">
              Amazon
            </a>
          </li>
        </ul>
        <h2 id="briefings-print">Briefings, print (The Discourse)</h2>
        <ul>
          <li>
            <a href="../static/documents/c1987__Boyd_John_R__Abstract__WORD-PDF.pdf">
              Abstract
            </a>
            , PDF, c1987
          </li>
          <li>
            <a href="../static/documents/2018-03__Boyd_John_R__edited_Hammond_Grant_T__A_Discourse_on_Winning_and_Losing.pdf">
              A Discourse on Winning and Losing
            </a>{" "}
            PPT as PDF, March, 2018 (All the briefings in one document, edited
            by Grant Hammond)
          </li>
          <li>
            <span>Conceptual Spiral</span>
            <ul>
              <li>
                <a href="../static/documents/1992-08__Boyd_John_R__Conceptual_Spiral__PDF.pdf">
                  PDF, August, 1992
                </a>
              </li>
              <li>
                <a href="../static/documents/1992-08__Boyd_John_R__Conceptual_Spiral__PPT-PDF.pdf">
                  PPT as PDF, August, 1992
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span>Destruction and Creation</span>
            <ul>
              <li>
                <a href="../static/documents/1976-09-03__Boyd_John_R__Destruction_and_Creation.pdf">
                  PDF, September 3, 1973
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span>The Essence of Winning and Losing</span>
            <ul>
              <li>
                <a href="../static/documents/1995-06-28__Boyd_John_R__The_Essence_of_Winning_and_Losing__PPT-PDF.pdf">
                  PPT as PDF, June, 28, 1995
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="../static/documents/1979-08-04__Boyd_John_R__New_Conception_for_Air-to-Air_Combat__PDF.pdf">
              New Conception for Air-to-Air Combat
            </a>
            , PDF, August 4, 1979
          </li>
          <li>
            <span>Organic Design for Command and Control</span>
            <ul>
              <li>
                <a href="../static/documents/1987-05__Boyd_John_R__Organic_Design_for_Command_and_Control__PDF.ppt">
                  PDF, May, 1987
                </a>
              </li>
              <li>
                <a href="../static/documents/1987-05__Boyd_John_R__Organic_Design_for_Command_and_Control__PPT-PDF.pdf">
                  PPT as PDF, June, 28, 1995
                </a>
              </li>
              <li>
                <a href="../static/documents/1987-05__Boyd_John_R__Organic_Design_for_Command_and_Control__PPT.ppt">
                  PPT, June, 28, 1995
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span>Patterns of Conflict</span>
            <ul>
              <li>
                <a href="../static/documents/1986-12__Boyd_John_R__Patterns_of_Conflict__PDF.pdf">
                  PDF, December, 1986
                </a>
              </li>
              <li>
                <a href="../static/documents/1986-12__Boyd_John_R__Patterns_of_Conflict__PPT-PDF.pdf">
                  PPT as PDF, June, 28, 1995
                </a>
              </li>
              <li>
                <a href="../static/documents/1986-12__Boyd_John_R__Patterns_of_Conflict__PPT.ppt">
                  PPT, June, 28, 1995
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="../static/documents/1987__Boyd_John_R__Revelation__PPT-PDF.pdf">
              Revelation
            </a>
            , PDF, 1987
          </li>
          <li>
            <span>The Strategic Game of ? and ?</span>
            <ul>
              <li>
                <a href="../static/documents/1987-06__Boyd_John_R__The_Strategic_Game_of_?_and_?__PDF.pdf">
                  PDF, June, 1987
                </a>
              </li>
              <li>
                <a href="../static/documents/1987-06__Boyd_John_R__The_Strategic_Game_of_?_and_?__PPT-PDF.pdf">
                  PPT as PDF, June, 1987
                </a>
              </li>
              <li>
                <a href="../static/documents/1987-06__Boyd_John_R__The_Strategic_Game_of_?_and_?__PPT.ppt">
                  PPT, June, 1987
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <h2 id="briefings-video">Briefings, video</h2>
        <h3>Patterns of Conflict, date unknown</h3>
        <p><span className="book-title">Note: the sound is mono and the volume is low. If you know how to edit the audio of a video, please email.</span></p>
        <ul>
          <li>
            <a href="https://vimeo.com/438949169/995093de05">Video 1</a>
          </li>
          <li>
            <a href="https://vimeo.com/438949188/31a2ddf219">Video 2</a>
          </li>
          <li>
            <a href="https://vimeo.com/441069188/c943541fbb">Video 3</a>
          </li>
          <li>
            <a href="https://vimeo.com/441069206/2ef7e99d92">Video 4</a>
          </li>
          <li>
            <a href="https://vimeo.com/438949295/ccd3fb8201">Video 5</a>
          </li>
          <li>
            <a href="https://vimeo.com/441069225/f4d51c4873">Video 6</a>
          </li>
          <li>
            <a href="https://vimeo.com/441069249/16b7f43b4f">Video 7</a>
          </li>
          <li>
            <a href="https://vimeo.com/438949210/367bd50203">Video 8</a>
          </li>
          <li>
            <a href="https://vimeo.com/438949228/81fbbdaec5">Video 9</a>
          </li>
          <li>
            <a href="https://vimeo.com/438949258/1289855d64">Video 10</a>
          </li>
          <li>
            <a href="https://vimeo.com/438949276/253f0209a0">Video 11</a>
          </li>
          <li>
            <a href="https://vimeo.com/441071301/13b9024f20">Video 12</a>
          </li>
          <li>
            <a href="https://vimeo.com/441071285/8386affee5">Video 13</a>
          </li>
          <li>
            <a href="https://vimeo.com/474531688">Video 14</a>
          </li>
        </ul>
        <h2 id="diagrams">Diagrams</h2>
        <img
          alt="Full OODA diagram originally drawn by Boyd for briefings."
          src="../static/images/2008-04-19__Moran_Patrick_E__Full_OODA_diagram.png"
        />
        <p>
          Full OODA diagram originally drawn by Boyd for briefings. (
          <a href="../static/images/2008-04-19__Moran_Patrick_E__Full_OODA_diagram.png">
            full-size
          </a>
          , source:{" "}
          <a href="https://en.wikipedia.org/wiki/John_Boyd_(military_strategist)#/media/File:OODA.Boyd.svg">
            Wikipedia
          </a>
          ).
        </p>
        <h2 id="lectures-talks">Lectures & Talks</h2>
        <ul>
          <li>
            <a href="../static/documents/2012__Hammond_Grant_T.pdf">
              On The Making of History: John Boyd and American Security
            </a>
            , Grant T. Hammond, PDF, 2012
          </li>
        </ul>
        <h2 id="personal-papers">Personal Papers</h2>
        <ul>
          <li>
            <a href="../static/documents/c2018__The_Personal_Papers_of_Colonel_John_R_Boyd_Part_II.pdf">
              The Personal Papers of Colonel John R. Boyd (USAF), Part II
            </a>
          </li>
        </ul>
        <h2 id="photos">Photos</h2>
        <img
          alt="Boyd in 1952 after earning his wings as an Air Force pilot."
          src="../static/images/1952_Boyd.jpg"
        />
        <p>
          Boyd in 1952 after earning his wings as an Air Force pilot (
          <a href="../static/images/1952_Boyd.jpg">full-size</a>, source:{" "}
          <a href="http://www.aviation-history.com/airmen/boyd.htm">
            Aviation History
          </a>
          ).
        </p>
        <img
          alt="Boyd stepping out of plane, cropped."
          src="../static/images/boyd_stepping_out_of_plane_cropped_500x500.jpg"
        />
        <p>
          Boyd stepping out of plane, cropped (
          <a href="../static/images/boyd_stepping_out_of_plane_cropped_500x500.jpg">
            full-size
          </a>
          ).
        </p>
        <img
          alt="F-100 taking off from Nellis AFB."
          src="../static/images/c1959_F100_taking_off_from_Nellis_AFB.jpg"
        />
        <p>
          F-100 taking off from Nellis AFB (
          <a href="../static/images/c1959_F100_taking_off_from_Nellis_AFB.jpg">
            full-size
          </a>
          , source:{" "}
          <a href="http://www.aviation-history.com/airmen/boyd.htm">
            Aviation History
          </a>
          ).
        </p>
        <img
          alt="Boyd post retirement."
          src="../static/images/Boyd_post_retirement.jpg"
        />
        <p>
          Boyd post retirement (
          <a href="../static/images/Boyd_post_retirement.jpg">full-size</a>,
          source:{" "}
          <a href="http://www.aviation-history.com/airmen/boyd.htm">
            Aviation History
          </a>
          ).
        </p>
        <h2 id="videos">Videos</h2>
        <ul>
          <li>
            <a href="https://www.c-span.org/video/?158365-1/boyd-fighter-pilot-changed-art-war">
              Brian Lamb interviews Robert Coram about his 2002 book{" "}
              <span className="book-title">
                Boyd: The Fighter Pilot Who Changed the Art of War
              </span>
            </a>{" "}
            (CSPAN, December 19, 2002)
          </li>
          <li>
            <a href="https://www.c-span.org/video/?17753-1/us-military-reform-oper-desert-storm">
              Boyd testifies to the House Armed Services Committee on U.S.
              Military Reform After Operation Desert Storm
            </a>{" "}
            (CSPAN, April 30, 1991)
          </li>
          <li>
            <a href="https://jinsa.org/jinsa_event/john-boyd-most-important-american-strategist-you-never-heard-of/">
              John Boyd: The Most Important American Strategist You Never Heard
              Of
            </a>{" "}
            (JINSA, June 10, 2020)
          </li>
        </ul>
        <h2 id="webpages">Webpages</h2>
        <h4>Articles, academic or niche</h4>
        <ul>
          <li>
            <a href="https://fhs.brage.unit.no/fhs-xmlui/handle/11250/2647802">
              Boyd's OODA Loop
            </a>{" "}
            by Chet Richards (Necesse, Vol 5, Issue 1, 2020)
          </li>
          <li>
            <a href="https://fasttransients.files.wordpress.com/2015/04/tremblayshapingadapting.pdf">
              Shaping and Adapting: Unlocking the power of Colonel John Boyd’s
              OODA Loop
            </a>{" "}
            by Maj. Paul Tremblay Jr., USMC (April 22, 2015; hosted at{" "}
            <a href="https://slightlyeastofnew.com/439-2/">
              Chet Richards' site Slightly East of New
            </a>
          </li>
          <li>
            <a href="https://www.jstor.org/stable/26276108">
              The Wingman-Philosopher of MiG Alley: John Boyd and the OODA Loop
            </a>{" "}
            by Scott E. McIntosh (Air Power History, Vol. 58, No. 4, 2011)
          </li>
        </ul>
        <h4>Articles, popular</h4>
        <ul>
          <li>
            <a href="https://www.fastcompany.com/44983/strategy-fighter-pilot">
              The Strategy of the Fighter Pilot, by Keith H. Hammonds
            </a>{" "}
            (FastCompany, May 31, 2002)
          </li>
        </ul>
        <h4>Biographies & Profiles</h4>
        <ul>
          <li>
            <a href="https://en.wikipedia.org/wiki/John_Boyd_%28military_strategist%29">
              Wikipedia entry for John Boyd
            </a>
          </li>
          <li>
            <a href="http://www.arlingtoncemetery.net/jrboyd.htm">
              Boyd's profile on arlingtoncemetery.net
            </a>
          </li>
          <li>
            <a href="http://www.ausairpower.net/APA-Boyd-Papers.html">
              Air Power Australia's profile of Boyd, compiled by Col. Chet
              Richards, USAF (Ret'd), PhD
            </a>
          </li>
          <li>
            <a href="http://www.aviation-history.com/airmen/boyd.htm">
              The Aviation History Online Museum's profile of Boyd
            </a>
          </li>
        </ul>
        <h4 id="chet-richards">Chet Richards</h4>
        <ul>
          <li>
            <a href="https://slightlyeastofnew.com/page-1">
              Slightly East of New
            </a>
          </li>
        </ul>
        <h4>Compendiums</h4>
        <ul>
          <li>
            <a href="http://dnipogo.org/john-r-boyd/">John Boyd Compendium</a>{" "}
            (Defense and National Interest by the Project on Government
            Oversight)
          </li>
          <li>
            <a href="http://dnipogo.org/strategy-and-force-employment/boyd-and-military-strategy/">
              Boyd and Military Strategy
            </a>{" "}
            (Defense and National Interest by the Project on Government
            Oversight)
          </li>
          <li>
            <a href="http://chuckspinney.blogspot.com/p/compendium-colonel-john-boyds.html">
              Introduction to the Strategic Theories of John Boyd
            </a>{" "}
            (The Blaster, the blog of Franklin C. (Chuck) Spinney)
          </li>
          <li>
            <a href="https://slightlyeastofnew.com/439-2/">
              List of articles about or deriving from Boyd's work at Chet
              Richards' site
            </a>
          </li>
          <li>
            <a href="https://www.colonelboyd.com/">
              www.colonelboyd.com
            </a>{" "}
            (another site dedicated to Col. John Boyd)
          </li>
        </ul>
        <h4 id="chuck-spinney">Franklin C. (Chuck) Spinney</h4>
        <ul>
          <li>
            <a href="https://chuckspinney.blogspot.com/p/about-me.html">
              The Blaster
            </a>
          </li>
          <li>
            <a href="https://fasttransients.files.wordpress.com/2019/06/evolutionary-epistemology-v2.5.pdf">
              Evolutionary Epistemology: A Personal View of John Boyd’s
              “Destruction and Creation”...and its centrality to the...OODA Loop
            </a>{" "}
            by Franklin C. Spinney (v2.5, 2019)
          </li>
        </ul>
        <h4>Identifiers</h4>
        <ul>
          <li>
            <a href="http://id.worldcat.org/fast/452556/">
              FAST Authority File
            </a>
          </li>
          <li>
            <a href="http://id.loc.gov/authorities/names/n00006520.html">
              U.S. Library of Congress Name Authority File (LCNAF)
            </a>
          </li>
          <li>
            <a href="https://www.wikidata.org/wiki/Q3080407">
              Wikidata item page
            </a>
          </li>
          <li>
            <a href="https://www.worldcat.org/identities/lccn-n00006520/">
              WorldCat Identity
            </a>
          </li>
        </ul>
        <h4>Obituaries, Eulogies & Remembrances</h4>
        <ul>
          <li>
            <a href="https://www.nytimes.com/1998/01/04/magazine/the-lives-they-lived-col-john-r-boyd-a-fighter-on-many-fronts.html?searchResultPosition=39">
              The Lives They Lived: Col. John R. Boyd; A Fighter on Many Fronts
            </a>{" "}
            by David H. Hackworth, Jan. 4, 1998, The New York Times
          </li>
          <li>
            <a href="https://www.nytimes.com/1997/03/13/us/col-john-boyd-is-dead-at-70-advanced-air-combat-tactics.html?searchResultPosition=4">
              Col. John Boyd Is Dead at 70; Advanced Air Combat Tactics
            </a>{" "}
            by Robert Mcg. Thomas Jr., Mar. 13, 1997, The New York Times
          </li>
          <li>
            <a href="https://slightlyeastofnew.com/2012/03/23/the-essential-boyd/">
              The Essential Boyd
            </a>{" "}
            by Grant T. Hammond (March 11, 1997)
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
